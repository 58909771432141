import { marketPlace } from '@/axios'

export default {
  addNewFlashSale (payload) {
    return marketPlace().post('merchant/flash-sales', payload)
  },
  getOffers (id) {
    return marketPlace().get(`merchant/flash-sales/${id}`)
  }
}
