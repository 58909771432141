<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addMerchant'})">
    إضافة عرض
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="8">
            <div>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> معلومات العرض</h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-3">
                    <input-form placeholder="أدخل الاسم" label="الإسم" v-model="offer.name" name="product name" validate="required"></input-form>
                  </b-col>
                  <b-col md="12" class="mb-3">
                    <main-select :options="['date', 'quantity']" label-title= 'نوع العرض' dir="rtl" v-model="offer.type" :searchable="false"/>
                  </b-col>
                  <b-col md="12" v-if="offer.type === 'date'" class="mb-3">
                    <b-form-group
                        :label="'بداية العرض'"
                        :label-for="'بداية العرض'"
                        class="position-relative"
                    >
                      <validation-provider
                          :name="'start_date'"
                          rules="required"
                          :bails="false"
                          #default="{errors}"
                      >
                        <i class="las la-calendar position-absolute text-gray font-size-20 datePickerIcon"></i>
                        <flat-pickr v-model="offer.start_date" :config="config" :class="['form-control bg-white date-error' , { 'is-invalid': errors.length > 0 }]"></flat-pickr>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" v-if="offer.type === 'date'" class="mb-3">
                    <b-form-group
                        :label="'انتهاء العرض'"
                        :label-for="'انتهاء العرض'"
                        class="position-relative"
                    >
                      <validation-provider
                          :name="'end_date'"
                          rules="required"
                          :bails="false"
                          #default="{errors}"
                      >
                        <i class="las la-calendar position-absolute text-gray font-size-20 datePickerIcon"></i>
                        <flat-pickr v-model="offer.end_date" :config="config" :class="['form-control bg-white date-error' , { 'is-invalid': errors.length > 0 }]"></flat-pickr>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-boxes"></i> المنتجات</h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-3">
                    <main-select label-title= 'المنتجات' multiple :options="allProducts" dir="rtl"
                                 v-model="selectedProduct" label="name"
                                 :selectable="option => !selectedProduct.includes(option.id)"
                                 :reduce="product => product.id" @deselected="removeProduct" @selecting="addProduct"
                                  @selectAll="handleSelectAll"
                    />
                    <div v-for="(item, key) in offer.flash_sale_products" :key="key" class="mb-3 bg-gray-light iq-border-radius-5 p-3">
                       <div class="w-100 d-flex gap_1 align-items-center">
                         <img class="avatar-90 iq-border-radius-5" :src="item.product.featured_image"  :alt="item.name"/>
                         <div>
                           <p class="text-primary font-weight-bold mt-2 m-0">{{item.product.name}}</p>
                           <p class="text-primary font-weight-bold m-0"><span class="text-dark d-inline-block mx-1">السعر </span>{{item.product.price}} SAR</p>
                         </div>
                       </div>
                      <b-row class="mt-4">
                        <b-col lg="4" class="mb-3">
                          <input-form placeholder="السعر" label="السعر بعد الخصم" @input='changePrice(item)' v-model="item.price" name="price" validate="required|numeric"></input-form>
                        </b-col>
                        <b-col lg="4" class="mb-3">
                          <input-form placeholder="النسبة" label="النسبة" @input='changePercentage(item)' v-model="item.percentage" name="percentage" validate="required|decimal"></input-form>
                        </b-col>
                        <b-col lg="4" class="mb-3" v-if="offer.type === 'quantity'">
                          <input-form placeholder="الكمية" label="الكمية" v-model="item.quantity" name="quantity" validate="required|numeric"></input-form>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </b-col>
          <b-col md="3">
            <b-card class="iq-border-radius-10 mb-3">
              <template v-slot:header>
                <h5 class="text-primary">
                  <i class="las la-save"></i>
                  النشر
                </h5>
              </template>
              <div class="d-flex justify-content-end gap_1" v-if="!loadingSubmit">
                <b-button variant="primary" class="text-white w-100" type="submit"> حفظ </b-button>
              </div>
              <div class="d-flex justify-content-end gap_1" v-else>
                <b-button variant="primary" class="text-white w-100" disabled type="submit"> <spinner-loading text="loading"></spinner-loading> </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import productServices from '@/modules/products/services/products'
import offersServices from '@/modules/offers/services/offers'
import { extend } from 'vee-validate'

extend('decimal', {
  validate: value => {
    // Check if the value is a valid decimal number
    const regex = /^\d+(\.\d+)?$/ // /^\d+(\.\d{1,2})?$/
    return regex.test(value)
  },
  message: 'Please enter a valid percentage (up to 2 decimal places).'
})
export default {
  components: { },
  mounted () {
    core.index()
  },
  data () {
    return {
      offer: {
        name: '',
        start_date: '',
        end_date: '',
        type: 'date',
        flash_sale_products: []
      },
      loadingSubmit: false,
      config: {
        minDate: 'today',
        altInput: true,
        dateFormat: 'Y-m-d',
        enableTime: true
      },
      allProducts: [],
      selectedProduct: []
    }
  },
  methods: {
    getOffer () {
      offersServices.getOffers(this.$route.params.id).then(res => {
        this.offer.name = res.data.name || ''
        this.offer.start_date = res.data.start_date || ''
        this.offer.end_date = res.data.end_date || ''
        this.offer.status = res.data.status || ''
        this.offer.translation_id = res.data.translation_id || ''
        this.offer.type = res.data.type || ''
        this.offer.flash_sale_products = res.data.products || ''
      })
    },
    changePrice (obj) {
      obj.percentage = (Number(obj.product.price) - Number(obj.price)) / Number(obj.product.price) * 100
    },
    changePercentage (obj) {
      obj.price = +obj.product.price * (1 - +obj.percentage / 100)
      // obj.price = (Number(obj.percentage) / 100) * Number(obj.product.price)
    },
    getAllMerchantProducts () {
      productServices.getAllMerchantProducts().then(res => {
        this.allProducts = res.data.data
      })
    },
    addProduct (productData) {
      console.log('test', productData)
      this.offer.flash_sale_products.push({
        product: productData,
        price: '',
        percentage: '',
        quantity: ''
      })
    },
    handleSelectAll () {
      this.selectedProduct = this.allProducts.map(product => product.id)

      this.offer.flash_sale_products = this.allProducts.map(product => ({
        product,
        price: '',
        percentage: '',
        quantity: ''
      }))
    },
    removeProduct (productData) {
      var position = this.offer.flash_sale_products.findIndex(product => product.product.id === productData.id)
      this.offer.flash_sale_products.splice(position, 1)
    },
    onSubmit () {
      offersServices.addNewFlashSale(this.offer).then(res => {
        core.showSnackbar(res.data.message)
        this.$router.push({ name: 'offers' })
      })
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getOffer()
    }
    this.getAllMerchantProducts()
  }
}
</script>
0
